import styled from "styled-components";
import { SectionMain, Container, Heading2, Paragraph } from "./Container";
import MediaMin from "../global/MediaMin";
import { getImageUrl } from "../Provider/Api";
import theme from "../global/Theme";

const SectionExt = styled(SectionMain)`
  height: 60vh;
  ${MediaMin.md} {
    height: 100vh;
  }
  background-position: center center;
  position: relative;
`;
const Content = styled.article`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  h2 {
    color: ${(prop) => prop.bgColor ? prop.bgColor : theme.whiteColor};
    text-shadow: 0 0 2px #000;
  }
`;

const ImageHeader = ({ item }) => (
  <SectionExt
    bgImage={item.imgName ? getImageUrl(item.imgName) : ""}
    bgColor={item.backgroundColor}
  >
    <Container mdMW="70%">
      <Content bgColor={item.backgroundColor || ""}>
        {item.viewHeading === "1" && <Heading2>{item.heading}</Heading2>}
        <Paragraph>{item.SubHeading}</Paragraph>
      </Content>
    </Container>
  </SectionExt>
);

export default ImageHeader;
