import axios from "axios";

let header = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    withCredentials: false,
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
};

const baseURL = "https://d2057z2iq79qyw.cloudfront.net/uploads/24/";
const videosBaseURL = "https://d2057z2iq79qyw.cloudfront.net/uploads/24/videos/";
const imageBaseUrl = "https://d2057z2iq79qyw.cloudfront.net/";

export async function getPageInfo(slugname) {
  const url = "/api/" + slugname;
  if (url) {
    return await axios.get(url, header);
  }
}

export async function getAllPageData(url) {
  return await axios.get("/api" +url, header);
}

export async function getMenu() {
  const url = baseURL + "menu.json";
  return await axios.get(url, header);
}

export function getImageUrl(image) {
  return image != null ? imageBaseUrl + image : '';
}

export function getVideoBaseUrl() {
  return videosBaseURL;
}